<template>
  <review-card
    :key="`list-group-crom-${expanded}`"
    title="Cromossomos(as)"
    :explanation="explanation"
  >
    <v-list-group
      v-for="(item, index) in chromosomes"
      :key="index"
      class="item-par"
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item-title>
          Cromossomo {{ item.chromosome.order }}
        </v-list-item-title>
      </template>
      <div class="py-3 grey--text text--darken-3">
        <property-value property="Comentários" :value="item.patientComments" />
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "Chromosomes",
  props: {
    chromosomes: {
      type: Array,
      default: () => [],
    },
    explanation: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
